<template>
  <div>
      {{quizToEdit()}}
    <ValidationObserver v-slot="{ handleSubmit }" >
        <form @submit.prevent="handleSubmit(onSubmit)" class="add_quiz">
            <h6>Edit Quiz</h6>

            <section v-if="errored">
                {{errInfo}}
            </section>

            {{error}}

            <div class="form-group">
                <label>question: </label>
                <ValidationProvider v-slot="{ errors }" rules="required">
                    <textarea class="u-full-width" placeholder="Enter Quiz" v-model="question"></textarea>
                    <span>{{errors[0]}}</span>
                </ValidationProvider>
            </div>

            <div class="form-group" v-for="(option, index) in options" :key="index">
            <label>
                option {{index + 1}}:
                <span class="correct_option_span">
                <input
                type="checkbox"
                :disabled="selectedoptions.length >= maxSelectableOption && selectedoptions.indexOf(index) == -1"
                v-model="selectedoptions"
                :value="index"
                v-on:click="selectOption($event, index)"
                />
                Correct answer
                </span>
                <ValidationProvider v-slot="{ errors }" rules="required">
                <textarea class="u-full-width" placeholder="Enter option" v-model="options[index]"></textarea>
                <span>{{errors[0]}}</span>
                </ValidationProvider>
            </label>
            </div>

            <!-- <div class="form-group">
                <button class="add-option-btn" @click="addOption" v-if="options.length < optionsMaxLength" >+</button>
                <button class="del-option-btn" v-if="options.length > optionsMinLength" @click="removeOption" >-</button>
            </div> -->

            <!-- <div class="form-group">
                <input type="checkbox" v-model="allow_multiple_answers" v-on:click="allowMultipleAns($event)">
                Allow selection of multiple answers
            </div> -->

            <!-- <div class="form-group">
                <label>author: </label>
                <ValidationProvider v-slot="{ errors }" rules="required">
                <input type="text" class="u-full-width" placeholder="Enter author" v-model="author">
                <span> {{errors[0]}} </span>
                </ValidationProvider>
            </div> -->

            <!-- <div class="form-group">
            <label>points: </label>
            <ValidationProvider v-slot="v" rules="required|positive">
                <input type="number" v-model="point">
                <span> {{v.errors[0]}} </span>
            </ValidationProvider>
            </div> -->

            <!-- <div class="form-group">
            <label>Correct answer(s) explanation: </label>
            <textarea class="u-full-width" placeholder="Correct answer(s) explanation" v-model="explanation"></textarea>
            </div> -->

            <button class="add-quiz-btn" type="submit">Edit Quiz</button>
        </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import '@/util/validation';

const { mapGetters } = createNamespacedHelpers('staging/quizzes');

export default {
  name: 'EditQuiz',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      // data needed for business logic
      error: '',
      zero: 0,
      minanswersArrLength: 1,
      minNumOfoptions: 1,
      maxNumOfoptions: 4,
      maxSelectableOption: 1,
      optionsMinLength: 1,
      optionsMaxLength: 4,
      selectedoptions: [],

      // data needed to construct the quiz for the api endpoint
      book_id: this.quizToEdit().id,
      question: this.quizToEdit().question,
      options: this.quizToEdit().options,
    };
  },
  methods: {
    ...mapGetters(['quizToEdit']),
    addOption(e) {
      e.preventDefault();
      this.options.push('');
    },
    removeOption(e) {
      e.preventDefault();
      this.options.pop();
    },
  },
  computed: {
    ...mapGetters(['errInfo', 'errored', 'loading']),
  },

};
</script>

<style>

</style>
